import React from "react";

function Home() {
    return (
        <section classNmae ="intro" id="home">
            <h1> Hello Lyn-nux World! </h1> 
            <p className="section__subtitle section__subtitle--intro"> Software Developer</p>

        </section>
    )
}
export default Home;
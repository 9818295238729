import React from 'react';
import profileImage from '../images/pic1.jpg';

function AboutMe() {
  return (
    <section className="about-me" id="about-me">
      <h2 className="section__title section__title--about-me">Who Is Lynn</h2>
      <p className="section__title section__title--about-me"></p>

      <div className="about-me--body">
        <p>
          Hello, I'm Lynn, and I'm excited to showcase my portfolio. Currently a third-year computer science major at Virginia Tech, I have a deep passion for all things tech. Computer science has always captivated me with its endless possibilities, from innovative software to cutting-edge machine learning. My coursework has fueled my knowledge, and I've actively engaged in hackathons and coding competitions to further sharpen my skills and collaborate with fellow enthusiasts.

          Beyond the world of coding, you'll often find me exploring the great outdoors in my free time. 
          Hiking and biking are my go-to adventures, allowing me to connect with nature and take a break from the digital realm. 
          When I'm not on the trails or diving into lines of code, I have a creative side as well. I enjoy creating paintings as a way to document my memories and celebrate the people I hold dear. This blend of tech and art is at the heart of who I am, and I'm thrilled to share this multifaceted journey with you through this portfolio. Thanks 
          for joining me on this adventure; let's explore the world of code and creativity together.
        </p>
      </div>
      <img src={profileImage} alt="atlanta pic" />
    </section>
  );
}

export default AboutMe;

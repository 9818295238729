import React from 'react';

function Contact() {
  return (
    <footer>
      <h4 className="section__title section__title--contact"> Contact</h4>
      <p>
        <strong>Email:</strong> <a href="mailto:phamlt21@vt.edu" className="footer__link">phamtl21@vt.edu</a>
      </p>
      <p>
        <strong>LinkedIn:</strong> <a href="https://www.linkedin.com/in/phamlt21-vt" className="footer__link">phamlt21-vt</a>
      </p>
      <p>
        <strong>Github:</strong> <a href="https://github.com/phamlt1?tab=repositories" className="footer__link">phamlt1</a>
      </p>
    </footer>
  );
}

export default Contact;

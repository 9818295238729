import React, { useState } from 'react';
import './style.css'; // Import your CSS file for styling

function Projects() {
  const projectsData = [
    {
      title: 'Covid-19 Visualization',
      description:
        'The program takes in real data, analyzes and sorts it out based on specified categories, and presents the data to the readers with visualizing charts.',
    },
    {
      title: 'Space Colony Simulation',
      description:
        'A sorting program that takes inputs and categorizes the items/users.',
    },
    {
      title: 'Portfolio',
      description:
        'The program takes in real data, analyzes and sorts it out based on specified categories, and presents the data to the readers with visualizing charts.',
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNextProject = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === projectsData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPreviousProject = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? projectsData.length - 1 : prevIndex - 1
    );
  };

  return (
    <section className="projects" id="projects">
      <h2 className="section__title section__title--projects">What I Built</h2>
      <div className="slider">
        <div className="project-navigation">
          <div
            className="arrow left"
            onClick={goToPreviousProject}
          >
            &#9664;
          </div>
          <div className="project">
            <h3>{projectsData[currentIndex].title}</h3>
            <p>{projectsData[currentIndex].description}</p>
          </div>
          <div
            className="arrow right"
            onClick={goToNextProject}
          >
            &#9654;
          </div>
        </div>
      </div>
      <a href="#work" className="btn">
        Resume
      </a>
    </section>
  );
}

export default Projects;

import logo from './logo.svg';
import './App.css';
import Home from '../src/pages/home';
import Projects from '../src/pages/projects';
import Contact from '../src/pages/contact';
import AboutMe from '../src/pages/aboutme'


function App() {
  return (
    <div className="App">
      <header>
        {/* ... (header content) */}
      </header>
      <Home />
      <AboutMe />
      <Projects />
      <Contact />

    </div>
  );
}


export default App;
  